<template>
  <div class="ai-prediction-list">
    <div
      class="prediction-items"
      v-for="(item, index) in list[mark]"
      :key="index"
    >
      <span class="prediction-title">{{ item.title }}</span>
      <div class="main">{{ item.main }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aiPredictionList",
  props: {
    mark: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: {
        sd: [
          {
            title: "稻飞虱",
            main: "3 级，发生面积约 70 万亩",
          },
          {
            title: "稻纵卷叶螟",
            main: "2 级，发生面积约 55 万亩",
          },
          {
            title: "二化螟",
            main: "2 级，发生面积约 35 万亩",
          },
          {
            title: "水稻纹枯病",
            main: "3 级，发生面积约 80 万亩",
          },
          {
            title: "稻瘟病",
            main: "2 级，局部 3 级，发生面积约 70 万亩",
          },
        ],
        xm: [
          {
            title: "赤霉病",
            main: "3 级，发生面积约 70 万亩",
          },
          {
            title: "白粉病",
            main: "2 级，发生面积约 55 万亩",
          },
          {
            title: "叶枯病",
            main: "2 级，发生面积约 35 万亩",
          },
          {
            title: "纹枯病",
            main: "3 级，发生面积约 80 万亩",
          },
          {
            title: "黑穗病",
            main: "2 级，局部 3 级，发生面积约 70 万亩",
          },
          {
            title: "锈病",
            main: "2 级，局部 3 级，发生面积约 70 万亩",
          },
          {
            title: "蚜虫",
            main: "2 级，局部 3 级，发生面积约 70 万亩",
          },
        ],
        dp: [
          {
            title: "温室蚜虫",
            main: "3 级，发生面积约 70 万亩",
          },
          {
            title: "白粉虱",
            main: "2 级，发生面积约 55 万亩",
          },
          {
            title: "茶黄螨",
            main: "2 级，发生面积约 35 万亩",
          },
          {
            title: "红蜘蛛",
            main: "3 级，发生面积约 80 万亩",
          },
          {
            title: "蓟马",
            main: "2 级，局部 3 级，发生面积约 70 万亩",
          },
          {
            title: "霜霉病",
            main: "2 级，局部 3 级，发生面积约 70 万亩",
          },
          {
            title: "灰霉病",
            main: "2 级，局部 3 级，发生面积约 70 万亩",
          },
        ],
        dd: [
          {
            title: "豆天蛾虫",
            main: "3 级，发生面积约 70 万亩",
          },
          {
            title: "造桥虫",
            main: "2 级，发生面积约 55 万亩",
          },
          {
            title: "象鼻虫",
            main: "2 级，发生面积约 35 万亩",
          },
          {
            title: "食心虫",
            main: "3 级，发生面积约 80 万亩",
          },
          {
            title: "大豆蚜虫",
            main: "2 级，局部 3 级，发生面积约 70 万亩",
          },
          {
            title: "根腐病",
            main: "2 级，局部 3 级，发生面积约 70 万亩",
          },
        ],
      },
    };
  },
};
</script>

<style lang="less" scoped>
.ai-prediction-list {
  width: 285px;
  height: 50%;
  margin: 0 auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #3e90e5;
  }
  &::-webkit-scrollbar-track {
    background-color: #3e90e5;
  }
  .prediction-items {
    width: 100%;
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .prediction-title {
      display: inline-block;
      min-width: 70px;
      padding: 0 14px;
      height: 24px;
      background: #4992ff;
      border-radius: 12px 12px 12px 0px;
      text-align: center;
      line-height: 27px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }
    .main {
      width: 100%;
      line-height: 30px;
      min-height: 30px;
      background: #1a385d;
      border-radius: 0px 0px 5px 5px;
      padding: 0 16px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }
  }
}
</style>
